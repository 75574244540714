import React, { createContext, useState, useContext } from 'react';

const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
  const [isAudioPlayerVisible, setAudioPlayerVisible] = useState(false);
  const [sessionInfo, setSessionInfo] = useState();
  const [assignmentUuid, setAssignmentUuid] = useState();
  const [colegiaAssignmentId, setColegiaAssignmentId] = useState();
  const [mindfulnessExc, setMindfulnessExc] = useState([]);
  const [groupType, setGroupType] = useState();

  const toggleAudioPlayer = () => {
    setAudioPlayerVisible(prev => !prev);
  };

  return (
    <AudioPlayerContext.Provider value={{ isAudioPlayerVisible, toggleAudioPlayer, sessionInfo, setSessionInfo, setAudioPlayerVisible,
        assignmentUuid, setAssignmentUuid, colegiaAssignmentId, setColegiaAssignmentId,
        mindfulnessExc, setMindfulnessExc, groupType, setGroupType
     }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => {
  return useContext(AudioPlayerContext);
};
