import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { AmbiencePlayer } from './AmbiencePlayer';
import { showCallError } from '../../services/Validation';
import ErrorMsg from './ErrorMsg';
import { useAudioPlayer } from '../../services/AudioPlayerContext';
import MindfulnessSession from '../Mindfulness/MindfulnessSession';
import { getUserData, currentTimeStamptoRuby, getURLParams } from '../../services/helpers';
import { addFavourites, deleteFavourites, sessionInfoGroupList, getAmbienceList,listenedUpdate } from '../../services/Events';
import  "../../assets/stylesheets/shared/audio_player.scss";
import { update_assignemnts } from '../../services/colegia_constants';

export default function AudioPlayer() {
  // const AmbiencePlayer = ({isPlaying, setShowTools, ambienceAudios, ambienceDisplayRef}) => {
  const {state }= useLocation();

  // const msession = state.session
   let { uuid } = useParams();
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [showAmbience, setShowAmbience] = useState(false);

  const [err, setErr] = useState('');
  const [errFlag, setErrFlag] = useState(false);
  const [exploreMore, setExploreMore] = useState(false);
  // const [sessionInfo, setSessionInfo] = useState();
  const [ambienceAudios, setAmbienceAudios] = useState();
  const [loaded, setLoaded] = useState();
  const [gradient, setGradient] = useState("linear-gradient(to right, rgb(0, 0, 0) 34.2857%, rgb(199, 199, 204) 34.2857%)");  // 34.2857 is the corresponding percentage for the black color upto the thumb
  const [selectedOption, setSelectedOption] = useState('1.00');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(4);
  const { isAudioPlayerVisible, assignmentUuid, sessionInfo, colegiaAssignmentId, setAudioPlayerVisible, mindfulnessExc, groupType } = useAudioPlayer();
  const [showTools, setShowTools] = useState(null)
  const [pausedSeconds, setPausedSeconds] = useState();
  const [isfav, setIsFav] = useState();
  const [speed, setSpeed] = useState(1.00); // Default playback speed
  const [showSpeed, setShowSpeed] = useState(false); // Controls the visibility of the speed display
  const [displayPosition, setDisplayPosition] = useState(42.8); 
  const [scrollDistance, setScrollDistance] = useState(0); // Tracks the current scroll position
  
  const userInfo = getUserData();
  const navigate = useNavigate();
  const session_type = sessionInfo?.session_type
  const assignment_uuid = assignmentUuid
  const colegia_assignment_id = colegiaAssignmentId
  const is_mobile = window.innerWidth < 700
  const [buttonDirection, setButtonDirection] = useState(is_mobile ? 'right' : 'down')
  // references
  const audioPlayer = useRef();   // reference our audio component
  const progressBar = useRef();   // reference our progress bar
  const animationRef = useRef();  // reference the animation
  const buttonRef = useRef();
  const ambienceRef = useRef();
  const backTenRef = useRef();
  const playRef = useRef();
  const backbtnRef = useRef();
  const favRef = useRef();
  const ambienceDisplayRef = useRef();
  const scrollContainerRef = useRef(null);
  const horizontalScrollContainerRef = useRef(null);
  const location = useLocation();
  const initial_dur = (localStorage.getItem('duration'))

  const speed_filters = ['0.50', '0.75','1.00','1.25','1.50','2.00']

  async function updatePausedSeconds(completed = false){
    if ( Object.keys(userInfo).length !== 0){
      let status_timestamp = currentTimeStamptoRuby();
      const session_id = localStorage.getItem('session_id')
      let payload = 
        { mwsRequest:{ String: 
            {Request:
            { Session: { token: '' ,loggedin_user_type: userInfo.user_info.user_type},
            Client: {platform: 'web',identity: '', version:"1.0", session_hash:""},
            Api: {version: '1.0'},
            ManagedUser: {uuid:   userInfo.user_info.user_account_uuid},
            MindfulnessSession:{session_uuid: session_id, timestamp: status_timestamp}
        }}}}
      const curr_time = Number(localStorage.getItem('currentTime'))
      const sess_dur = Number(localStorage.getItem('duration'))
      localStorage.removeItem('isPlaying')

        if (sess_dur - curr_time > 5 && !completed){
          payload.mwsRequest.String.Request.MindfulnessSession = {...payload.mwsRequest.String.Request.MindfulnessSession, paused_seconds: curr_time}
        }
       
        if((assignment_uuid || colegia_assignment_id) && !completed){
          updateAssignment('incomplete')
        }

        if (curr_time != 0){
          try {         
            const response = await listenedUpdate(payload)
            if(response){
            if (response.data.mwsResponse.Status.code === '000'){
              console.log(response.data)
            }
            else{
                let error = response.data.mwsResponse.Status.message
                console.log(error)
                showCallError(error, setErr, setErrFlag)
            }
          }
        } catch (error) {
            showCallError(error, setErr, setErrFlag);
        }
      }
    }
  }

  useEffect(()=>{
    localStorage.setItem('duration',duration)
  },[duration])

  useEffect(()=>{
    localStorage.setItem('duration',duration)
  },[duration])

  useEffect(()=>{
    if (sessionInfo)
    {
       localStorage.setItem('session_id',sessionInfo.uuid)
    }
  },[sessionInfo])

  useEffect(()=>{
    const scrollContainer = scrollContainerRef.current;
    console.log(scrollContainer);
    scrollContainer.scrollTop = 0;
  },[exploreMore])

  useEffect(()=>{
     localStorage.setItem('isPlaying',isPlaying)
  },[isPlaying])

  useEffect(()=>{
    localStorage.setItem('currentTime', currentTime)
    progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
  },[currentTime, loaded])

  useEffect(()=>{
    setLoaded(false);
    setPausedSeconds(sessionInfo?.paused_seconds);
    setIsFav(sessionInfo?.is_favourite == 1 ? 'active' : '')
    progressBar.current.max =sessionInfo?.duration;
  }, [sessionInfo])

  async function getAmbienceAudios(payload){
    try {         
        const response = await getAmbienceList(payload)

        if(response){
        if (response.data.mwsResponse.Status.code === '000'){
          setAmbienceAudios(response.data.mwsResponse.AmbienceAudios)
        }
        else{
          let error = response.data.mwsResponse.Status.message
          console.log(error)
          showCallError(error, setErr, setErrFlag)
        }
      }
    } catch (error) {
        showCallError(error, setErr, setErrFlag);
    }
  }

  useEffect(()=>{
    if ( Object.keys(userInfo).length !== 0){
      let payload = 
      { mwsRequest:{ String: 
          {Request:
          { Session: { token: 'NO-TOKEN' ,loggedin_user_type: userInfo.user_info.user_type},
            Client: {platform: 'web',identity: '', version:"1.0", session_hash:""},
            Api: {version: '1.0'}
      }}}}
      getAmbienceAudios(payload)
    }
  },[])

  useEffect(() => {
    if (sessionInfo){
    const seconds = Math.floor(audioPlayer.current.duration);
    setDuration(seconds ? seconds : Number(initial_dur));
    if(seconds && !isNaN(seconds))
      progressBar.current.max = seconds;

    if (state !== undefined)
    audioPlayer?.current?.play();
    else
    audioPlayer?.current?.pause();

    setIsPlaying(state !== undefined ? true : false);
     animationRef.current = requestAnimationFrame(whilePlaying)
    if(ambienceRef.current){
      ambienceRef.current.style.display = 'block'
    }
  
    if(ambienceDisplayRef.current && ambienceDisplayRef.current.style.display == 'none' && ambienceRef.current && ambienceRef.current.style && ambienceRef.current.style.display == 'block'){
        setTimeout(function () {
          if(ambienceRef.current && ambienceDisplayRef.current && ambienceDisplayRef.current.style.display == 'none'){
            ambienceRef.current.style.display = 'none'
          }
        }, 10000);
      }
    }
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState, sessionInfo, loaded]);

  useEffect(()=>{
    if (pausedSeconds != null && Number(pausedSeconds) !=NaN && Number(pausedSeconds) > 15){
      progressBar.current.value = pausedSeconds;
      changeRange();
    }
  },[pausedSeconds, loaded]);

  function handleOutsideClick(event){
    if (ambienceRef.current) {
      if (ambienceRef.current.contains(event.target)) {
        // inside click
        return;
      }
      // outside click 
    }

    if (backTenRef.current) {
      if (backTenRef.current.contains(event.target)) {
        // inside click
        return;
      }
      // outside click 
    }

    if (playRef.current) {
      if (playRef.current.contains(event.target)) {
        // inside click
        return;
      }
      // outside click 
    }

    if (favRef.current) {
      if (favRef.current.contains(event.target)) {
        // inside click
        return;
      }
      // outside click 
    }

    if (backbtnRef.current) {
      if (backbtnRef.current.contains(event.target)) {
        // inside click
        return;
      }
      // outside click 
    }

    if (progressBar.current) {
      if (progressBar.current.contains(event.target)) {
        // inside click
        return;
      }
      // outside click 
    }

    if (buttonRef.current) {
      if (buttonRef.current.contains(event.target) &&  ambienceDisplayRef.current.style.display != 'none') {
        // inside click
        return;
      }
      // outside click 
      // setShowAmbience(false);
    }
  
    if( ambienceDisplayRef.current?.style?.display == 'none'){
         if( ambienceRef.current && ambienceRef.current.style.display == 'none'){
             ambienceRef.current.style.display='block'
         }
         else if( ambienceRef.current && ambienceRef.current?.style?.display == 'block'){
             ambienceRef.current.style.display='none'
         }
        
    }
      if(ambienceDisplayRef.current && ambienceDisplayRef.current?.style?.display == 'none' && ambienceRef.current && ambienceRef.current?.style && ambienceRef.current?.style?.display == 'block'){
        setTimeout(function () {
          if(ambienceRef.current && ambienceDisplayRef.current && ambienceDisplayRef.current?.style?.display == 'none'){
            ambienceRef.current.style.display = 'none'
          }
        }, 10000);
      }
  }


  useEffect(()=>{
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  },[])

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
      updatePausedSeconds();
    }
  }

  const whilePlaying = () => {
    if (audioPlayer.current){
      progressBar.current.value = audioPlayer.current.currentTime;
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  }

  const changePlayerCurrentTime = () => {
    // progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
    setCurrentTime(progressBar.current.value);
  }

  const backTen = () => {
    progressBar.current.value = Number(Number(progressBar.current.value) - 10);
    changeRange();
  }

  // useEffect(()=>{

  //    window.addEventListener('locationchange', (event)=>{
  //      event.preventDefault();
  //      console.log('locationchange')
  //     updatePausedSeconds();
  //       })
  //    return () => {
  //     window.removeEventListener('locationchange', updatePausedSeconds);
  //   };
  // },[])

  useEffect(()=>{
    window.addEventListener('popstate', (event)=>{
      event.preventDefault();
      let playing = localStorage.getItem('isPlaying')
      if(playing !=null){
        playing = playing.toString();
      if (playing == 'true'){
        updatePausedSeconds();
      }
    }
    })
    return () => {
      window.removeEventListener('popstate', updatePausedSeconds);
    };
  },[])

  // useEffect(()=>{
  //   window.addEventListener('load', (event)=>{
  //     event.preventDefault();
  //     updatePausedSeconds();
  //   })
  //   return () => {
  //     window.removeEventListener('load', updatePausedSeconds);
  //   };
  // },[])
  
  async function add_or_deletefav(){
    if ( Object.keys(userInfo).length !== 0){
      let payload = 
        { mwsRequest:{ String: 
            {Request:
            { Session: { token: '' ,loggedin_user_type: userInfo.user_info.user_type},
            Client: {platform: 'web',identity: '', version:"1.0", session_hash:""},
            Api: {version: '1.0'},
            ManagedUser: {uuid:   userInfo.user_info.user_account_uuid},
            MindfulnessSession:{session_uuid: sessionInfo?.uuid}
        }}}}

      if(isfav == 'active'){
        try {         
            const response = await deleteFavourites(payload)
            if (response.data.mwsResponse.Status.code === '000'){
                setIsFav('')
            }
            else{
              let error = response.data.mwsResponse.Status.message
              console.log(error)
              showCallError(error, setErr, setErrFlag)
            }
            
        } catch (error) {
            showCallError(error, setErr, setErrFlag);
        }
      }
      else{
          try {         
            const response = await addFavourites(payload)
            if (response.data.mwsResponse.Status.code === '000'){
                setIsFav('active')
            }
            else{
              let error = response.data.mwsResponse.Status.message
              console.log(error)
              showCallError(error, setErr, setErrFlag)
            }
          
      } catch (error) {
          showCallError(error, setErr, setErrFlag);
      }
      }
    }
  }

function toggleAmbience(){
  if (ambienceDisplayRef.current && ambienceDisplayRef.current.style.display == 'none'){
    ambienceDisplayRef.current.style.display = 'block';
  }
  else if(ambienceDisplayRef.current && ambienceDisplayRef.current.style.display == 'block')
  {
    ambienceDisplayRef.current.style.display = 'none';
  }

}

function endplay(){
  audioPlayer.current.pause();
  progressBar.current.style.setProperty('--seek-before-width', `100%`)
  cancelAnimationFrame(animationRef.current);
  setIsPlaying(false);
  const sess_dur = Number(localStorage.getItem('duration'))
  setCurrentTime(sess_dur);
  updatePausedSeconds(true);
  updateAssignment('complete');
}

function handleback(){
  if(state == undefined)
 {
  let playing = localStorage.getItem('isPlaying')
      if(playing !=null){
        playing = playing.toString();
      if (playing == 'true'){
        updatePausedSeconds();
      }
    }
 }
}

const handleSpeedChange = (amount) => {
  let newSpeed = speed + amount;

  if (newSpeed < 0.5) newSpeed = 0.5;
  if (newSpeed > 2) newSpeed = 2;

  setSpeed(newSpeed);

  setSelectedOption(newSpeed.toFixed(2));

  const percent = (newSpeed - 0.5) / (2 - 0.5);
  const newGradient = `linear-gradient(to right, #000 ${percent * 100}%, #C7C7CC ${percent * 100}%)`;
  setGradient(newGradient);

  if (audioPlayer.current) {
    audioPlayer.current.playbackRate = newSpeed;
  }
};

const handleChange = (event) => {
  const newSpeed = parseFloat(event.target.value);
  setSpeed(newSpeed);
  setShowSpeed(true);
  setSelectedOption(newSpeed)

  const { value, min, max } = event.target;
  const percent = (value - min) / (max - min);
  setDisplayPosition(percent * 100);
  
  // Update the background gradient based on the thumb's position
  const gradient = `linear-gradient(to right, #000 ${percent * 100}%, #C7C7CC ${percent * 100}%)`;
  setGradient(gradient); // Update the gradient dynamically

  if (audioPlayer.current) {
    audioPlayer.current.playbackRate = newSpeed;
  }
};

const handleFilterSelect = (filter) => {
  setSelectedOption(filter);

  setSpeed(parseFloat(filter));

  const percent = (parseFloat(filter) - 0.5) / (2.0 - 0.5);
  const gradient = `linear-gradient(to right, #000 ${percent * 100}%, #C7C7CC ${percent * 100}%)`;
  setGradient(gradient);

  if (audioPlayer.current) {
    audioPlayer.current.playbackRate = parseFloat(filter);
  }
};

const handleMouseUp = () => {
  setShowSpeed(false);
};

const handleTouchEnd = () => {
  setShowSpeed(false);
};

const handleScroll = (type) => {
  const scrollContainer = scrollContainerRef.current;
  if (scrollContainer) {
    const containerHeight = scrollContainer.scrollHeight;
    const containerVisibleHeight = scrollContainer.clientHeight;
    
    let newScrollDistance = scrollDistance;
    if (type === 'down') {
      newScrollDistance += 450;

      // Check if we've reached the bottom
      if (newScrollDistance + containerVisibleHeight >= containerHeight) {
        newScrollDistance = containerHeight - containerVisibleHeight;
        setButtonDirection('up');
      }

    } else if (type === 'up') {
      newScrollDistance -= 450;

      // Check if we've reached the top
      if (newScrollDistance <= 0) {
        newScrollDistance = 0;
        setButtonDirection('down');
      }
    }
    setScrollDistance(newScrollDistance);
    scrollContainer.scrollTo({
      top: newScrollDistance,
      behavior: 'smooth'
    });
  }
};

const handleManualScroll = () => {
  const scrollContainer = scrollContainerRef.current;
  if (scrollContainer) {
    const containerHeight = scrollContainer.scrollHeight;
    const containerVisibleHeight = scrollContainer.clientHeight;
    const currentScrollPosition = scrollContainer.scrollTop;
    if (currentScrollPosition + containerVisibleHeight >= containerHeight) {
      if (buttonDirection !== 'up') {
        setButtonDirection('up');
      }
    } else if (currentScrollPosition <= 0) {
      // if (buttonDirection !== 'down') {
        setButtonDirection('down');
      // }
    } else {
      if (buttonDirection === 'up') {
        setButtonDirection('down');
      }
    }
    setScrollDistance(currentScrollPosition)
  }
};

useEffect(() => {
  const scrollContainer = scrollContainerRef.current;
  if (scrollContainer) {
    scrollContainer.addEventListener('scroll', handleManualScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleManualScroll);
    };
  }
}, []);

const handleScrollOnMobile = (direction) => {
  const scrollContainer = horizontalScrollContainerRef.current;
  if (scrollContainer) {
    const containerWidth = scrollContainer.scrollWidth;
    const containerVisibleWidth = scrollContainer.clientWidth;
    let newScrollDistance = scrollDistance;
    if (direction === 'right') {
      newScrollDistance += 450; 

      if (newScrollDistance + containerVisibleWidth >= containerWidth) {
        newScrollDistance = containerWidth - containerVisibleWidth;
        setButtonDirection('left');
      }

    } else if (direction === 'left') {
      newScrollDistance -= 450;

      if (newScrollDistance <= 0) {
        newScrollDistance = 0;
        setButtonDirection('right');
      }
    }
    setScrollDistance(newScrollDistance);
    scrollContainer.scrollTo({
      left: newScrollDistance,
      behavior: 'smooth'
    });
  }
};

const handleMinimizeButton = (type) => {
  setAudioPlayerVisible(true);
  if (type !== 'not_home_page') {
    navigate('/home');
  }
  setExploreMore(false);
};

const handleMaximizeButton = () => {
  setAudioPlayerVisible(false);
  navigate(`/audio/${sessionInfo?.uuid}`);
};

function updateAssignment(assignment_status){
  if ( Object.keys(userInfo).length !== 0 && userInfo?.user_info?.session_type !== 'admin_session'){
    let payload = 
      { mwsRequest:{ String: 
          {Request:
          { Session: { token: '' ,loggedin_user_type: userInfo.user_info.user_type},
          Client: {platform: 'web',identity: '', version:"1.0", session_hash:""},
          Api: {version: '1.0'},
          ManagedUser: {uuid:   userInfo.user_info.user_account_uuid, email: userInfo.user_info.email},
          Assignment:{uuid: assignment_uuid, colegia_assignment_id: colegia_assignment_id, attachment_uuid: sessionInfo?.uuid, assignment_type: 'mindfulness', status: assignment_status}
      }}}}
    update_assignemnts(payload)
  }

}

  return (
    <div className={`audio_player_wrapper ${isAudioPlayerVisible ? 'minimize' : ''}`}>
      <div className={`audio_player_whole_container ${exploreMore ? 'explore' : ''} ${isAudioPlayerVisible ? 'minimize' : ''}`}>
     
     {!isAudioPlayerVisible &&
      <div className='audio_player_top_container'>
        <div className='back_btn_container'>
          {location.key!== 'default' &&<span className='back_icn' ref={backbtnRef} onClick={() =>{navigate(-1); handleback();} }></span>}
        </div>
        {
          !exploreMore && 
        <div className="explore_more_title_container" onClick={() => setExploreMore(true)}>
          <img className="explore_more_icon"></img>
          <div className="explore_more_text"> Explore more</div>
        </div>
        }
      </div>
      }
      { isAudioPlayerVisible &&
                <div onClick={togglePlayPause} ref={playRef} className='pause_play_container_mini'>
              {isPlaying ? <div className='minimized_pause_button'></div> : <div className='minimized_play_button'></div> }
          </div>}
      <div className={`audio_title_container ${isAudioPlayerVisible ? 'minimize' : ''}`}>
        {sessionInfo && <div className={`audio_title ${isAudioPlayerVisible ? 'minimize' : ''}`}>{sessionInfo.title}</div>}
        {!isAudioPlayerVisible && <div className='button_wrapper'>
          <div className='button_container'>
            <div className='backward_audio' ref={backTenRef} onClick={backTen}></div>
            <div onClick={togglePlayPause} ref={playRef} className='pause_play_container'>
                {isPlaying ? <div className='pause_button'></div> : <div className='play_button'></div> }
            </div>
            <div className={`fav_icn ${isfav}`} ref={favRef} onClick={add_or_deletefav}></div>
          </div>
        </div>
        }
        <div className='audio_player_container'>
          {
            <audio ref={audioPlayer} id= 'audio_player_id' onLoadedMetadata={()=>{setLoaded(true)}} onEnded={endplay} src={sessionInfo ? 
            sessionInfo.url
            : ''} preload="metadata"></audio>}
            {!isAudioPlayerVisible &&
          <>
          <div className='duration_value'>{calculateTime(currentTime)}</div><input type="range" className={`progressBar ${exploreMore ? 'explore' : ''}`} defaultValue="0" ref={progressBar} min={0} max={parseInt(Number(duration))} />
          <div className='duration_value'>{(duration && !isNaN(duration)) && calculateTime(duration)}</div></>}

          {isAudioPlayerVisible && <><input type="range" className={`progressBar ${window.location.href.indexOf("audio") > -1 ? ''  : 'opened'}` } defaultValue="0" ref={progressBar} min={0} max={parseInt(Number(duration))} /><div className='duration_container'>
              <div className='duration_values left'>{calculateTime(currentTime)}</div>
              <div className='duration_values right'>{(duration && !isNaN(duration)) && calculateTime(duration)}</div>
            </div></>}
        </div>
      </div>
      
      {!isAudioPlayerVisible && 
      <div className={`speed_value_container ${showTools === 'playback' ? 'active' : ''}`}>
          <div className='speedDisplay_container'>
              <div
                className='speedDisplay' onClick={()=>{showTools === 'playback' ?  setShowTools('null') : setShowTools('playback')}}
                // style={{ left: `calc(${displayPosition}%)`}}
              >
                {speed.toFixed(2)}x
            </div>
          </div>

          {showTools === 'playback' && <div className='playback_control_container'>
            <div className='playback_container'>
              {true && (
                  <div className='speedDisplay_container'>
                    <div
                      className='speedDisplay'
                      // style={{ left: `calc(${displayPosition}%)`}}
                    >
                      {speed.toFixed(2)}x
                  </div>
                  </div>
                  
              )}
              <div className='playback_bar_container' >
                <div className='minus_container'  onClick={() => handleSpeedChange(-0.25)}> </div>
              <input
                  type='range'
                  className='playbackBar'
                  min='0.5'
                  max='2'
                  step='0.25'
                  value={speed}
                  onChange={handleChange}
                  style={{ background: gradient }}
                  onMouseUp={handleMouseUp}
                  onTouchEnd={handleTouchEnd}
              />
              <div className='plus_container'  onClick={() => handleSpeedChange(0.25)}> </div>
              </div>
              <div className='playback_speed_options_container'>
                        {speed_filters.map((filter, index)=>{
                            const formattedFilter = parseFloat(filter);
                            const displayFilter = formattedFilter % 1 === 0 ? formattedFilter.toFixed(1) : formattedFilter.toString();
                            return <div className={`top_speed_filter_container ${selectedOption == filter ? 'active' : ''}`} 
                            onClick={() => handleFilterSelect(filter)}
                            key={filter}> 
                                <div id={index} className={`top_speed_filter_title ${selectedOption == filter ? 'active' : ''}`}>{displayFilter}</div>
                            </div>
                        })}
              </div>
            </div>

            {/* <div className='ambience_close_container playback'>
              <div className='ambience_close' onClick={()=>{setShowTools(null)}}></div>
          </div> */}
          </div>}

      </div>
      }
      {!isAudioPlayerVisible && <div className='volume_control_wrapper'> 
          <div className='volume_control_empty_cont'></div>
          <div className='volume_control_container' >
            <div className='volume_control' onClick={()=>{setShowTools('ambience')}}></div>
            {/* <div className='playback_control'  onClick={()=>{setShowTools('playback')}}></div> */}
          </div>
          <div className='minimize_button' onClick={handleMinimizeButton}> </div>
        </div>}
        {!isAudioPlayerVisible &&
        <div className='ambience_audio_container'  ref ={buttonRef}>
          <div className='ambience_ref_container' ref={ambienceDisplayRef}>
            {showTools === 'ambience' && <AmbiencePlayer isPlaying={isPlaying} setShowTools={setShowTools} ambienceAudios={ambienceAudios} ambienceDisplayRef={ambienceDisplayRef}/>}
            {/* {showTools === 'playback' && <div className='playback_control_container'>
              <div className='playback_container'>
              {true && (
                  <div className='speedDisplay_container'>
                    <div
                      className='speedDisplay'
                      style={{ left: `calc(${displayPosition}%)`}}
                    >
                      {speed.toFixed(2)}x
                  </div>
                  </div>
                  
              )}
              <input
                  type='range'
                  className='playbackBar'
                  min='0.25'
                  max='2'
                  step='0.05'
                  value={speed}
                  onChange={handleChange}
                  onMouseUp={handleMouseUp} // Hide speed display when the user stops dragging
                  onTouchEnd={handleTouchEnd} // Handle touch events for mobile
              />
              </div>
              <div className='ambience_close_container playback'>
                <div className='ambience_close' onClick={()=>{setShowTools(null)}}></div>
            </div>
            </div>} */}
          </div>
        </div>
      }
      {isAudioPlayerVisible &&
       <div className='maximize_button' onClick={handleMaximizeButton}></div>
       }
      </div>
      {
        <div className={`explore_more_container ${exploreMore && 'opened'}`} ref={scrollContainerRef}>
          <div className='explore_more_top_container'>
            <div className='explore_more_text_container'>
              <img className="explore_more_back_button" alt = "" onClick={() => setExploreMore(false)}></img>
              <div className='explore_more_top_title'> Explore more</div>
            </div>
            {/* <div className='explore_more_view_all'>View all </div> */}
            <Link to={`/home`}> <p className='explore_more_view_all' onClick={() => {handleMinimizeButton()}}>Alaia Home</p> </Link> 
          </div>
          {mindfulnessExc && mindfulnessExc.length !=0 && 
            <div className='mindfulness_session_group_container'> 
              <div className='audio_session_list_container' ref={horizontalScrollContainerRef}>
                  <ul className='content_ul_list'>
                    { mindfulnessExc.slice(0,8).map((m_session,index)=>{
                        return( <li className='content_list' key={m_session.id} onClick={() => handleMinimizeButton('not_home_page')}> <Link to={`/${groupType}/sessions/list/${groupType == 'individual' ?  m_session.uuid : m_session.id}`} state={{msession: m_session}}><MindfulnessSession msession={m_session} from={'explore_more'}/></Link></li> )
                      })}       
                  </ul>
              </div>
            </div>
          }
          <div className={`scroll_button ${exploreMore ? 'opened' : 'closed'}`} onClick={() => is_mobile ? handleScrollOnMobile(buttonDirection): handleScroll(buttonDirection)}>
            <img className={`scroll_icon ${(buttonDirection === ('down') || buttonDirection == 'right' ) ? 'down' : 'up'}`} /> 
          </div>
        </div>
      }
    </div>              
  )
}

export { AudioPlayer }
